import React from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import propTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { Link } from 'gatsby';
import './carousel.css';

const Carousel = props => {
  const { CarouselData } = props;
  const settings = {
    dots: false,
    speed: 500,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // const images = [];
  // for (const node of CarouselData) {
  //   images.push(
  //     <div>
  //       <Img fluid={slide.fluid} alt="Image"></Img>
  //     </div>
  //   );
  // }

  return (
    <Slider {...settings}>
      {CarouselData.map(items => (
        <div key={items.node.id}>
          <div className="site-Banner">
            <Img
              fluid={items.node.slide.fluid}
              alt={items.node.slide.description}
            />
            <div className="Banner-details">
              <div>
                <h1
                  className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                  style={{
                    boxShadow:
                      'rgb(0, 0, 0, 0) 0.5rem 0px 0px, rgb(0,0,0, 0) -0.5rem 0px 0px',
                    lineHeight: '1',
                    padding: '0.25em',
                  }}
                >
                  {items.node.title}
                </h1>
                <h3
                  className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                  style={{
                    boxShadow:
                      'rgb(0,0,0, 0) 0.5rem 0px 0px, rgb(0,0,0, 0) -0.5rem 0px 0px',
                    color: 'white',
                    lineHeight: '1',
                    padding: '0.25em',
                  }}
                >
                  {items.node.subHeading}
                </h3>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

// Carousel.propTypes = {
//   CarouselData: PropTypes.node.isRequired,
// };
// childImageSharpNode {
//   childImageSharp: {
//     fluid: any;
//   };

Carousel.propTypes = {
  CarouselData: propTypes.arrayOf(
    propTypes.shape({
      node: propTypes.shape({
        title: propTypes.string,
        subHeading: propTypes.string,
        slide: propTypes.shape({
          description: propTypes.string,
          fluid: propTypes.object,
        }),
      }),
    })
  ),
};

Carousel.defaultProps = {
  CarouselData: ``,
};

export default Carousel;
